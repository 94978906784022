import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import GeneralConst from "../../../../consts/GeneralConst.js";
import UserService from "../../../../services/UserService";
import { selectEmail } from "../../../../slices/userSlice";
import AlertStrings from "../../../../strings/AlertStrings.json";
import GeneralStrings from "../../../../strings/GeneralStrings.json";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../../../utilities/ValidationHelper";
import "./ChangePasswordModal.scss";

const ChangePasswordModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const email = useSelector(selectEmail);
  const [isLoading, setIsLoading] = useState(false);
  const formStrings = GeneralStrings.FORM;
  const STATUS_CODES = GeneralConst.STATUS_CODES;

  const handleSubmit = (formValues) => {
    setIsLoading(true);

    UserService.changePassword(
      email,
      formValues.currentPassword,
      formValues.newPassword
    )
      .then((response) => {
        if (
          response.data.status === STATUS_CODES.ERROR ||
          response.data.status === STATUS_CODES.DATA_ERROR
        ) {
          setFailedMessage(
            response?.data?.statusMessage || AlertStrings.GENERAL_ERROR
          );
        } else {
          setSuccessMessage(
            response?.data?.statusMessage || AlertStrings.GENERAL_SUCCESS
          );
        }
      })
      .catch((response) => {
        setFailedMessage(response?.message || AlertStrings.GENERAL_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        setIsLoading(false);
        toggle();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="change-password-modal"
    >
      <ModalHeader toggle={toggle}>{formStrings.CHANGE_PASSWORD}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values }) => (
            <Form className="padding-left-50 padding-right-50 margin-bottom-25 ">
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.CURRENT_PASSWORD}</FormLabel>
                <Field
                  name="currentPassword"
                  type="password"
                  className="form-control"
                  validate={validatePassword}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="currentPassword"
                />
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.NEW_PASSWORD}</FormLabel>
                <Field
                  name="newPassword"
                  type="password"
                  className="form-control"
                  validate={validatePassword}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="newPassword"
                />
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.CONFIRM_PASSWORD}</FormLabel>
                <Field
                  name="confirmPassword"
                  type="password"
                  className="form-control"
                  validate={(value) =>
                    validateConfirmPassword(values.newPassword, value)
                  }
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="confirmPassword"
                />
              </FormControl>
              <div className="flex flex-row">
                <Button
                  type="submit"
                  color="success"
                  disabled={isLoading}
                  className="text-lighter margin-left-auto"
                >
                  {GeneralStrings.FORM.SUBMIT}
                  {isLoading && (
                    <Spinner
                      color="dark"
                      size="sm"
                      className="margin-left-10"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ChangePasswordModal;
