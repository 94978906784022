/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "../../../components/DashboardNavbar/DashboardNavbar";
import GeneralConst from "../../../consts/GeneralConst";
import RoutesConst from "../../../consts/RoutesConst";
import { selectLoggedInStatus } from "../../../slices/userSlice";
import "./DashboardLayout.scss";

const TITLE = `Dashboard | ${GeneralConst.SITE_NAME}`;

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const loggedIn = useSelector(selectLoggedInStatus);
  const [isLoading, setIsLoading] = useState(true);

  /*
    Handles loggedIn state, if user is not logged in,
    they are redirected to the login page, all dashboard pages
    should have this layout set so this functionality is implemented
   */
  useEffect(() => {
    !loggedIn && navigate(RoutesConst.LOGIN);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Box sx={{ display: "flex" }} className="dashboard-layout">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <CssBaseline />
      <DashboardNavbar />
      <Box
        className="dashboard-main-container"
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
