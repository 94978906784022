import { Button } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import Recaptcha from "react-recaptcha";
import { Alert, Spinner } from "reactstrap";
import GeneralConst from "../../consts/GeneralConst";
import logoWhite from "../../images/logos/logoWhite.png";
import MailService from "../../services/MailService";
import AlertStrings from "../../strings/AlertStrings.json";
import GeneralStrings from "../../strings/GeneralStrings.json";
import {
  validateEmail,
  validateMessage,
  validateName,
  validateSubject,
} from "../../utilities/ValidationHelper";
import "./Contact.scss";

const Contact = () => {
  const [recaptchaLoad, setRecaptchaLoad] = useState(false);
  const [isVerified, setIsVerified] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const contactStrings = GeneralStrings.CONTACT;
  const STATUS_CODES = GeneralConst.STATUS_CODES;

  const recaptchaLoaded = () => {
    setRecaptchaLoad(true);
  };

  const verifiedRecaptcha = (response) => {
    if (response) {
      setIsVerified(response);
    }
  };

  const resetErrorMessages = () => {
    setSentMessage(false);
    setFailedMessage(false);
  };

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    resetErrorMessages();

    if (recaptchaLoad && isVerified) {
      MailService.submitContactForm(formValues)
        .then((response) => {
          if (response.data.status === STATUS_CODES.ERROR) {
            setFailedMessage(
              response?.data?.statusMessage || AlertStrings.GENERAL_ERROR
            );
          } else {
            setSentMessage(
              response?.data?.statusMessage || AlertStrings.GENERAL_SUCCESS
            );
          }
        })
        .catch((response) => {
          setFailedMessage(
            response?.data?.statusMessage || AlertStrings.GENERAL_ERROR
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    setIsLoading(false);
  };

  return (
    <div className="section section-black bg-white text-white" id="contact">
      <div className="container-fluid">
        <div className="title-header text-center">
          <h2>{contactStrings.TITLE}</h2>
          <div className="separator">
            <img src={logoWhite} className="seperator-img" alt="logo" />
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xs-12">
              <h2 className="contact-form-header">
                {contactStrings.DESCRIPTION}
              </h2>
            </div>

            <div className="col-lg-8 col-xs-12">
              <div className="contact-form col-md-12">
                {failedMessage && <Alert color="danger">{failedMessage}</Alert>}
                {sentMessage && <Alert>{sentMessage}</Alert>}

                <Formik
                  initialValues={{
                    email: "",
                    name: "",
                    message: "",
                    subject: "",
                  }}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  <Form>
                    <div className="form-group">
                      <Field
                        name="name"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.YOUR_NAME}
                        validate={validateName}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="name"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        name="email"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.YOUR_EMAIL}
                        validate={validateEmail}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="email"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        name="subject"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.SUBJECT}
                        validate={validateSubject}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="subject"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as="textarea"
                        name="message"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.MESSAGE}
                        validate={validateMessage}
                        rows={5}
                        type="textarea"
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="message"
                      />
                    </div>

                    <div className="form-group">
                      <Recaptcha
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        render="explicit"
                        onloadCallback={recaptchaLoaded}
                        verifyCallback={verifiedRecaptcha}
                      />
                    </div>

                    <div className="form-group margin-top-20">
                      <Button
                        fullWidth
                        disabled={isLoading}
                        className="btn btn-bbz-primary btn-contact-submit"
                        type="submit"
                      >
                        <span className="submit-text">
                          {GeneralStrings.SUBMIT}
                        </span>
                        {isLoading && <Spinner color="dark" size="sm" />}
                      </Button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
