/* eslint-disable import/no-anonymous-default-export */
export default {
  // TODO: See if we can remove paramaterized queries and pass values in object body
  API: {
    ARTISTS: {
      ADD_ARTIST: "/artists/AddArtist",
      DELETE_ARTIST: "/artists/DeleteArtist?id={id}",
      GET_ALL_ARTISTS: "/artists/GetAllArtists",
      GET_ALL_ARTISTS_SPOTIFY_IDS: "/artists/GetAllArtistsSpotifyIds",
      GET_TOTAL_ARTISTS_COUNT: "/artists/GetTotalArtistsCount",
    },
    AVA: {
      ADD_SITE_VIEW: "/ava/AddSiteView?domain={domain}",
      GET_SITE_VIEW_COUNT: "/ava/GetSiteViewCount?domain={domain}",
      SUBMIT_HELP_REQUEST: "/ava/SubmitHelpRequest",
    },
    BLOG: {
      ADD_BLOG_POST: "/blog/AddBlogPost",
      DELETE_BLOG_POST: "/blog/DeleteBlogPost?id={id}",
      EDIT_BLOG_POST: "/blog/EditBlogPost",
      GET_ALL_BLOG_POSTS: "/blog/GetAllBlogPosts",
      GET_BLOG_POST_BY_ID: "/blog/GetBlogPostById?id={id}",
    },
    FAQ: {
      ADD_FAQ: "/faq/AddFAQ",
      DELETE_FAQ: "/faq/DeleteFAQ?id={id}",
      GET_ALL_FAQS: "/faq/GetAllFAQs",
      GET_FAQ_BY_ID: "/faq/GetFAQById?id={id}",
    },
    MAIL: {
      SUBMIT_CONTACT_FORM: "/mail/SubmitContactForm",
    },
    USERS: {
      ADD_USER: "/users/AddUser",
      CHANGE_PASSWORD:
        "/users/ChangePassword?email={email}&currentPassword={currentPassword}&newPassword={newPassword}",
      DELETE_USER: "/users/DeleteUser?email={email}",
      GENERATE_TEMPORARY_PASSWORD:
        "/users/GenerateTemporaryPassword?email={email}",
      GET_ALL_USERS: "/users/GetAllUsers",
      GET_TOTAL_USER_COUNT: "/users/GetTotalUserCount",
      GET_USER_BY_EMAIL: "/users/GetUserByEmail?email={email}",
      LOGIN: "/users/Login?email={email}&password={password}",
      RESET_PASSWORD: "/users/ResetPassword?email={email}&password={password}",
      UPDATE_USER_PROFILE:
        "/users/UpdateUserProfile?email={email}&firstName={firstName}&lastName={lastName}&position={position}&profilePic={profilePic}",
    },
  },
  SPOTIFY_ENDPOINT: "https://api.spotify.com/v1/artists?ids={ids}",
  YOUTUBE_STATISTICS_ENDPOINT:
    "https://www.googleapis.com/youtube/v3/channels?part=statistics&id={channelId}&key={apiKey}",
  YOUTUBE_VIDEO_ENDPOINT:
    "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId={channelId}&order=date&type=video&key={apiKey}",
};
