/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class MailService {
  /**
   * [POST]
   * Submits customer contact form
   * @returns {Promise}
   */
  submitContactForm({ subject, name, email, message }) {
    return axios({
      method: "post",
      url: getAPIUrl() + EndpointsConst.API.MAIL.SUBMIT_CONTACT_FORM,
      headers: {},
      data: { subject, name, email, message },
    });
  }
}

export default new MailService();
