import Avatar from "@mui/joy/Avatar";
import { Link } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import logoBlack from "../../images/logos/logoBlack.png";
import ArtistService from "../../services/ArtistService";
import GeneralStrings from "../../strings/GeneralStrings";
import { getArtists } from "../../utilities/SpotifyHelper";
import "./Artists.scss";

const Artists = () => {
  const [artists, setArtists] = useState([]);
  const artistsStrings = GeneralStrings.ARTISTS;

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    draggable: true,
    infinite: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: 7,
    speed: 4000,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    ArtistService.getAllArtistsSpotifyIds().then((res) => {
      getArtists(res.data).then((res) => {
        setArtists(res);
      });
    });
  }, []);

  /* useEffect(() => {
    switch (windowWidth) {
      case windowWidth > 1000:
        setSettings({ ...defaultSettings, slidesToShow: 6 });
        break;
      case windowWidth > 500 && windowWidth < 1000:
        setSettings({ ...defaultSettings, slidesToShow: 3 });
        break;
      case windowWidth > 100 && windowWidth < 500:
        setSettings({ ...defaultSettings, slidesToShow: 2 });
        break;
      default:
        setSettings({ ...defaultSettings, slidesToShow: 1 });
        break;
    }
  }, [windowWidth]); */

  return (
    <div className="section artists" id="artists">
      <div className="container">
        <div className="title-area">
          <h2>{artistsStrings.TITLE}</h2>
          <div className="separator">
            <img src={logoBlack} className="seperator-img" alt="logo" />
          </div>
          <p className="artist-description">{artistsStrings.DESCRIPTION}</p>
        </div>
      </div>

      <div className="artists-slider">
        <div className="slider-container">
          <Slider {...settings} className="artists-slide">
            {!_.isEmpty(artists) &&
              artists.map((artist) => (
                <div className="artist" key={"artist-" + artist?.name}>
                  <Link
                    href={artist?.external_urls?.spotify}
                    underline="none"
                    className="flex flex-col"
                    target="_blank"
                  >
                    <Avatar
                      className="artist-img"
                      color="neutral"
                      size="lg"
                      src={artist?.images[0]?.url}
                      variant="soft"
                    />
                    <span className="artist-name text-center">
                      {artist?.name}
                    </span>
                  </Link>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Artists;
