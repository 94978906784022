import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import AccountPositions from "../../../consts/AccountPositionsConst.js";
import UserService from "../../../services/UserService.js";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../utilities/ValidationHelper.js";
import "./EditUserModal.scss";

const EditUserModal = ({
  editValue,
  editFirstNameValue,
  editLastNameValue,
  editPositionValue,
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userStrings = GeneralStrings.DASHBOARD.USERS;

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    const profileData = { email: editValue, ...formValues };

    UserService.updateUserProfile(profileData)
      .then(() => {
        setSuccessMessage(AlertStrings.UPDATE_USER_PROFILE_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.UPDATE_USER_PROFILE_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        setIsLoading(false);
        toggle();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="edit-user-modal"
    >
      <ModalHeader toggle={toggle}>{userStrings.EDIT_USER}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            firstName: editFirstNameValue,
            lastName: editLastNameValue,
            position: editPositionValue,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values }) => (
            <Form className="padding-left-50 padding-right-50 margin-bottom-25">
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.FIRST_NAME}</FormLabel>
                <Field
                  name="firstName"
                  className="form-control"
                  validate={validateTextField}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="firstName"
                />
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.LAST_NAME}</FormLabel>
                <Field
                  name="lastName"
                  className="form-control"
                  validate={validateTextField}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="lastName"
                />
              </FormControl>
              <FormControl className="margin-bottom-35">
                <FormLabel>{GeneralStrings.FORM.POSITION}</FormLabel>
                <Field
                  as="select"
                  className="form-control select-control"
                  name="position"
                >
                  <option></option>
                  {AccountPositions.map((position) => (
                    <option key={position} value={position}>
                      {position}
                    </option>
                  ))}
                </Field>
              </FormControl>
              <div className="flex flex-row">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isLoading}
                  className="text-lighter margin-left-auto"
                >
                  {GeneralStrings.FORM.SUBMIT}
                  {isLoading && (
                    <Spinner
                      color="dark"
                      size="sm"
                      className="margin-left-10"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default EditUserModal;
