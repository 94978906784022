import { Table } from "@mui/joy";
import { Alert, Button, Grid, Paper, Snackbar } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import RoutesConst from "../../../consts/RoutesConst";
import AWSService from "../../../services/AWSService";
import BlogService from "../../../services/BlogService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import "./BlogPage.scss";

const BlogPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [showDeleteBlogPostModal, setShowDeleteBlogPostModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const [deleteImgLoc, setDeleteImgLoc] = useState(null);
  const blogStrings = GeneralStrings.DASHBOARD.BLOG;

  const toggleDeleteBlogPostModal = () =>
    setShowDeleteBlogPostModal(!showDeleteBlogPostModal);

  const addBlogPost = () => {
    navigate(RoutesConst.DASHBOARD.BLOG.ADD_POST);
  };

  const editBlogPost = (event) => {
    const postId = event.target.getAttribute("data-value") || null;
    navigate({
      pathname: RoutesConst.DASHBOARD.BLOG.EDIT_POST,
      search: createSearchParams({
        id: postId,
      }).toString(),
    });
  };

  const deleteBlogPost = (event) => {
    setDeleteValue(event.target.getAttribute("data-value") || null);
    setDeleteImgLoc(event.target.getAttribute("data-imgloc") || null);
    toggleDeleteBlogPostModal();
  };

  const handleDelete = (formData) => {
    const { deleteValue, secondaryDeleteValue } = formData;
    const fileNameSplit = secondaryDeleteValue.split("/");
    const currentFileName = fileNameSplit.pop();

    AWSService.deleteBlogImage(currentFileName)
      .then(() => {
        BlogService.deleteBlogPost(deleteValue)
          .then(() => {
            setSuccessMessage(AlertStrings.DELETE_BLOG_POST_SUCCESS);
          })
          .catch(() => {
            setFailedMessage(AlertStrings.DELETE_BLOG_POST_ERROR);
          })
          .finally(() => {
            setShowToast(true);
            toggleDeleteBlogPostModal();
          });
      })
      .catch(() => {
        setFailedMessage(AlertStrings.DELETE_BLOG_IMAGE_FAIL);
        setShowToast(true);
      });
  };

  useEffect(() => {
    BlogService.getAllBlogPosts()
      .then((response) => {
        const blogPosts = response?.data;
        setPosts(blogPosts);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.GENERAL_ERROR);
        setShowToast(true);
      });
  }, [searchParams, showToast]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="users-page">
        <DashboardSubPageToolbar
          addButtonText={blogStrings.ADD_POST}
          addFunction={addBlogPost}
          title={blogStrings.BLOG}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              hoverRow
              stripe="odd"
              variant="plain"
            >
              <thead>
                <tr>
                  <th style={{ width: "60%" }}>{blogStrings.TITLE}</th>
                  <th style={{ width: "25%" }}>{blogStrings.DATE}</th>
                  <th style={{ width: "15%" }}></th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(posts) &&
                  posts.map((post) => (
                    <tr key={"posts-" + post.title}>
                      <td>{post.title}</td>
                      <td>{post.date}</td>
                      <td className="flex flex-row">
                        <Button
                          className="dashboard-button margin-right-10 margin-horizonal-center"
                          color="primary"
                          variant="contained"
                          data-value={post.id}
                          onClick={editBlogPost}
                        >
                          {GeneralStrings.FORM.EDIT}
                        </Button>
                        <Button
                          className="dashboard-button margin-horizonal-center"
                          color="error"
                          variant="contained"
                          data-value={post.id}
                          data-imgloc={post.imgLoc}
                          onClick={deleteBlogPost}
                        >
                          {GeneralStrings.FORM.DELETE}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Paper>
        </Grid>
        <ConfirmDeleteModal
          deleteMessage={blogStrings.DELETE_DESCRIPTION}
          handleDelete={handleDelete}
          isOpen={showDeleteBlogPostModal}
          deleteValue={deleteValue}
          secondaryDeleteValue={deleteImgLoc}
          toggle={toggleDeleteBlogPostModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default BlogPage;
