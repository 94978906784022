import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import FAQService from "../../../services/FAQService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../utilities/ValidationHelper";
import "./AddFAQModal.scss";

const AddFAQModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const faqStrings = GeneralStrings.DASHBOARD.FAQ;

  const handleSubmit = (formValues) => {
    setIsLoading(true);

    FAQService.addFAQ(formValues)
      .then(() => {
        setSuccessMessage(AlertStrings.ADD_FAQ_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.ADD_FAQ_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        setIsLoading(false);
        toggle();
      });
  };

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} className="add-faq-modal">
      <ModalHeader toggle={toggle}>{faqStrings.ADD_FAQ}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            question: "",
            answer: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="padding-left-50 padding-right-50 margin-bottom-25 ">
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.QUESTION}</FormLabel>
              <Field
                name="question"
                component="textarea"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="question"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.ANSWER}</FormLabel>
              <Field
                name="answer"
                component="textarea"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="answer"
              />
            </FormControl>
            <div className="flex flex-row">
              <Button
                type="submit"
                color="success"
                disabled={isLoading}
                className="text-lighter margin-left-auto"
              >
                {GeneralStrings.FORM.SUBMIT}
                {isLoading && (
                  <Spinner color="dark" size="sm" className="margin-left-10" />
                )}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddFAQModal;
