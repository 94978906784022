import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { List } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst.js";
import UserService from "../../services/UserService.js";
import { logout, selectEmail } from "../../slices/userSlice";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./DashboardAvatarMenu.scss";

const DashboardAvatarMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector(selectEmail);
  const [userInfo, setUserInfo] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const NAV_STRINGS = GeneralStrings.DASHBOARD.NAV;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(RoutesConst.DEFAULT);
  };

  useEffect(() => {
    UserService.getUserByEmail(email).then((res) => {
      setUserInfo(res.data);
    });
  }, [email]);

  return (
    <div className="dashboard-avatar-menu">
      <Chip
        onClick={handleClick}
        icon={<Avatar className="avatar" src={userInfo?.profilePic} />}
        className="menu-container"
        label={<SettingsIcon className="settings-icon" />}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="menu-dropdown">
          <div className="dropdown-header-container">
            <p
              className="dropdown-header"
              dangerouslySetInnerHTML={{
                __html: GeneralStrings.DASHBOARD.WELCOME_MESSAGE_HTML.replace(
                  "{name}",
                  `${userInfo?.firstName} ${userInfo?.lastName}`
                ),
              }}
            />
            <p className="dropdown-subheader">{userInfo?.position}</p>
          </div>
          <hr />
          <List className="dashboard-avatar-list">
            <ListItem
              component="a"
              href={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
              className="dashboard-avatar-list-item"
            >
              <ListItemIcon className="item-icon">
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
                className="item-text"
                primary={NAV_STRINGS.ACCOUNT_SETTINGS}
              />
            </ListItem>
            <ListItem className="dashboard-avatar-list-item">
              <ListItemIcon className="item-icon">
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
                className="item-text"
                primary={NAV_STRINGS.EMAIL}
              />
            </ListItem>
            <ListItem
              component="button"
              onClick={handleLogout}
              className="dashboard-avatar-list-item"
            >
              <ListItemIcon className="item-icon">
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
                className="item-text"
                primary={NAV_STRINGS.LOGOUT}
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default DashboardAvatarMenu;
