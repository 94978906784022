import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import RoutesConst from "./consts/RoutesConst";
import Blog from "./pages/Blog/Blog";
import ViewBlogPost from "./pages/Blog/ViewBlogPost";
import AccountSettings from "./pages/Dashboard/AccountSettings/AccountSettings";
import ArtistsPage from "./pages/Dashboard/ArtistsPage/ArtistsPage";
import AddBlogPost from "./pages/Dashboard/BlogPage/AddBlogPost/AddBlogPost";
import BlogPage from "./pages/Dashboard/BlogPage/BlogPage";
import EditBlogPost from "./pages/Dashboard/BlogPage/EditBlogPost/EditBlogPost";
import Dashboard from "./pages/Dashboard/Dashboard";
import FAQPage from "./pages/Dashboard/FAQPage/FAQPage";
import HelpPage from "./pages/Dashboard/Help/HelpPage";
import UsersPage from "./pages/Dashboard/Users/UsersPage";
import FAQs from "./pages/FAQs/FAQs";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage/LoginPage";
import Projects from "./pages/Projects/Projects";
export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Routes>
        <Route index path={RoutesConst.DEFAULT} element={<Homepage />} />
        <Route path={RoutesConst.DASHBOARD.DEFAULT} element={<Dashboard />} />
        <Route
          path={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
          element={<AccountSettings />}
        />
        <Route path={RoutesConst.DASHBOARD.ARTISTS} element={<ArtistsPage />} />
        <Route
          path={RoutesConst.DASHBOARD.BLOG.DEFAULT}
          element={<BlogPage />}
        />
        <Route
          path={RoutesConst.DASHBOARD.BLOG.ADD_POST}
          element={<AddBlogPost />}
        />
        <Route
          path={RoutesConst.DASHBOARD.BLOG.EDIT_POST}
          element={<EditBlogPost />}
        />
        <Route path={RoutesConst.DASHBOARD.FAQ} element={<FAQPage />} />
        <Route path={RoutesConst.DASHBOARD.HELP} element={<HelpPage />} />
        <Route path={RoutesConst.DASHBOARD.USERS} element={<UsersPage />} />
        <Route path={RoutesConst.PROJECTS} element={<Projects />} />
        <Route path={RoutesConst.BLOG.DEFAULT} element={<Blog />} />
        <Route path={RoutesConst.BLOG.VIEW_POST} element={<ViewBlogPost />} />
        <Route path={RoutesConst.FAQ} element={<FAQs />} />
        <Route path={RoutesConst.LOGIN} element={<LoginPage />} />
      </Routes>
    );
  }
}
